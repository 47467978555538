.contacts-container {
    width: 90%;
    margin: auto;
}

.search-bar {
    width: 100%;
    padding: 10px;
    margin: 20px 0;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

table,
th,
td {
    border: 1px solid #ddd;
}

th,
td {
    padding: 12px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

.edit-contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.edit-contact-form label {
    font-weight: bold;
}

.edit-contact-form input,
.edit-contact-form textarea {
    font-family: Arial, sans-serif;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.hidden {
    display: none;
}
.notes-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #ddd;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.notes-table td {
    border: 1px solid #ddd;
}

.notes-table button:hover {
    background-color: #45a049;
}
/* Update the existing button styles */
.edit-contact-form button:first-child {
    /* Cancel button */
    background-color: #f8f9fa;
    border: 2px solid #dee2e6;
    color: #495057;
    padding: 12px 12px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.edit-contact-form button:first-child:hover {
    background-color: #e9ecef;
    border-color: #ced4da;
}

.edit-contact-form button:last-child {
    /* Save button */
    background-color: #28a745;
    border: none;
    color: white;
    padding: 12px 12px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-contact-form button:last-child:hover {
    background-color: #218838;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
}

/* Add spacing between buttons */
.edit-contact-form button {
    margin: 0 8px;
    font-weight: 500;
}

.edit-contact-form {
    text-align: left;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
}

.pagination button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}