.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

h1 {
  font-size: 2em;
  margin: 0;
}

.underline {
  border-bottom: 1px solid #000;
  /* Black underline */
  /* Ensure the rest of the input styling does not have borders */
  border-top: none;
  border-left: none;
  border-right: none;
}